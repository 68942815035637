<template>
  <div>
    <v-dialog
      v-model="dialog"
      scrollable
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :disabled="FIELDS_EXEPT_DISABLED"
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-account-search-outline</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>Select User</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 500px;">
          <v-list>
            <v-list-item-group
              v-model="selectedUser"
              mandatory
              color="indigo"
            >
              <v-list-item
                v-for="(user, index) in activeClients"
                :key="user.uid"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="user.auth.displayName"></v-list-item-title>
                  <v-list-item-subtitle>
                    #{{ index+1 }} {{ user.auth.email }} {{ user.auth.phone }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="blue lighten-1"
            text
            @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="userSelected"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { listAdviserUsers, getCurrentUser } from '@/services/firebase';

export default {
  name: 'UserSelectDialog',
  props: {
    buttonTitle: {
      type: String,
      default: 'USERS',
    },
  },
  data() {
    return {
      selectedUser: undefined,
      clients: [],
      dialog: false,
    };
  },
  computed: {
    ...mapGetters(['FIELDS_EXEPT_DISABLED']),
    activeClients() {
      return this.clients.filter((c) => !c.auth.disabled);
    },
  },
  methods: {
    userSelected() {
      this.dialog = false;
      this.$emit('userSelected', this.clients[this.selectedUser]);
    },
  },
  mounted() {
    listAdviserUsers(getCurrentUser().uid)
      .then((response) => {
        response.forEach((u) => {
          const record = {
            uid: u.id,
            ...u.data(),
          };
          this.clients.push(record);
        });
      });
  },
};
</script>

<style scoped>

</style>
