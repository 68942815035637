var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseSection', {
    attrs: {
      "id": _vm.section.key,
      "is-visible": _vm.isSectionVisible,
      "is-modified": _vm.isDataChanged,
      "section": _vm.section
    },
    on: {
      "onSectionSaveClick": _vm.save
    },
    scopedSlots: _vm._u([{
      key: "section",
      fn: function fn() {
        var _vm$common$client, _vm$common$client$aut;

        return [_c('v-toolbar', {
          attrs: {
            "elevation": "0"
          }
        }, [_c('v-btn', {
          attrs: {
            "disabled": _vm.FIELDS_DISABLED && !_vm.IS_CLIENT,
            "text": ""
          },
          on: {
            "click": _vm.createSubApplication
          }
        }, [_c('v-icon', [_vm._v("mdi-account-plus")]), _vm._v(" NEW APPLICANT ")], 1), _c('v-btn', {
          attrs: {
            "text": ""
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.printApplication.apply(null, arguments);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-printer")]), _vm._v(" PRINT ")], 1), !_vm.IS_CLIENT ? _c('v-btn', {
          staticClass: "copy-btn",
          attrs: {
            "text": ""
          },
          on: {
            "click": _vm.copyDialogToggle
          }
        }, [_c('v-icon', [_vm._v("mdi-content-copy")]), _vm._v(" Copy ")], 1) : _vm._e(), !_vm.IS_CLIENT ? _c('v-btn', {
          staticClass: "complain-btn",
          class: {
            'with-content': _vm.isComplainContent
          },
          attrs: {
            "text": ""
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.toggleComplain.apply(null, arguments);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-bullhorn")]), _vm._v(" " + _vm._s(_vm.complainTogglerText) + " complain ")], 1) : _vm._e()], 1), _c('v-col', [_c('v-btn', {
          attrs: {
            "color": "primary",
            "loading": _vm.calcSaving
          },
          on: {
            "click": _vm.anzCalc
          }
        }, [_vm._v(" ANZ calc ")])], 1), !_vm.IS_CLIENT ? _c('v-dialog', {
          attrs: {
            "width": "500"
          },
          model: {
            value: _vm.copyDialog,
            callback: function callback($$v) {
              _vm.copyDialog = $$v;
            },
            expression: "copyDialog"
          }
        }, [_c('v-card', [_c('v-card-title', {
          staticClass: "text-h5 grey lighten-2"
        }, [_vm._v(" Do You want to copy the application with all data? ")]), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "color": "primary",
            "text": ""
          },
          on: {
            "click": _vm.copyDialogToggle
          }
        }, [_vm._v(" No ")]), _c('v-btn', {
          attrs: {
            "color": "primary",
            "text": ""
          },
          on: {
            "click": _vm.copyApplication
          }
        }, [_vm._v(" Yes ")])], 1)], 1)], 1) : _vm._e(), _c('transition', {
          attrs: {
            "name": "fade"
          }
        }, [_vm.copyAlert ? _c('v-alert', {
          staticClass: "copy-alert",
          attrs: {
            "type": "success"
          }
        }, [_vm._v(" Application was copied successfully! ")]) : _vm._e()], 1), !_vm.IS_CLIENT ? _c('v-card-text', {
          staticClass: "mt-4"
        }, [_c('div', {
          staticClass: "mb-5"
        }, [_c('v-container', [[_c('v-row', [_c('v-col', [_c('v-select', {
          attrs: {
            "disabled": _vm.FIELDS_EXEPT_DISABLED,
            "items": _vm.statusOptions,
            "label": "Pipeline status"
          },
          model: {
            value: _vm.common.status,
            callback: function callback($$v) {
              _vm.$set(_vm.common, "status", $$v);
            },
            expression: "common.status"
          }
        })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-select', {
          attrs: {
            "items": _vm.viewOptions,
            "label": "Who can view the application",
            "disabled": _vm.canNotChangeView || _vm.FIELDS_EXEPT_DISABLED
          },
          model: {
            value: _vm.common.view,
            callback: function callback($$v) {
              _vm.$set(_vm.common, "view", $$v);
            },
            expression: "common.view"
          }
        })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-select', {
          attrs: {
            "items": _vm.editOptions,
            "label": "Who can edit the application",
            "disabled": _vm.canNotChangeEdit
          },
          on: {
            "change": _vm.editRoleChange
          },
          model: {
            value: _vm.common.edit,
            callback: function callback($$v) {
              _vm.$set(_vm.common, "edit", $$v);
            },
            expression: "common.edit"
          }
        })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-text-field', {
          attrs: {
            "disabled": _vm.FIELDS_EXEPT_DISABLED,
            "value": (_vm$common$client = _vm.common.client) === null || _vm$common$client === void 0 ? void 0 : (_vm$common$client$aut = _vm$common$client.auth) === null || _vm$common$client$aut === void 0 ? void 0 : _vm$common$client$aut.displayName,
            "label": "Client",
            "readonly": ""
          },
          scopedSlots: _vm._u([{
            key: "append",
            fn: function fn() {
              return [_c('UserSelectDialog', {
                on: {
                  "userSelected": _vm.clientChanged
                }
              })];
            },
            proxy: true
          }, {
            key: "append-outer",
            fn: function fn() {
              return [_c('v-tooltip', {
                attrs: {
                  "bottom": ""
                },
                scopedSlots: _vm._u([{
                  key: "activator",
                  fn: function fn(_ref) {
                    var on = _ref.on,
                        attrs = _ref.attrs;
                    return [_c('v-btn', _vm._g(_vm._b({
                      attrs: {
                        "disabled": _vm.FIELDS_EXEPT_DISABLED
                      }
                    }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-email")])], 1)];
                  }
                }], null, false, 1364290575)
              }, [_c('span', [_vm._v("Send invitation email")])])];
            },
            proxy: true
          }], null, false, 3689217273)
        })], 1)], 1)], _vm.isAdmin ? _c('v-row', [_c('v-col', [_c('v-autocomplete', {
          attrs: {
            "return-object": "",
            "autocomplete": "nofill",
            "label": "Adviser",
            "items": _vm.adviserList,
            "item-text": "name"
          },
          model: {
            value: _vm.common.adviser,
            callback: function callback($$v) {
              _vm.$set(_vm.common, "adviser", $$v);
            },
            expression: "common.adviser"
          }
        })], 1)], 1) : _vm._e(), _c('br'), _c('p', {
          staticClass: "author"
        }, [_c('b', [_vm._v("Author:")]), _vm._v(" " + _vm._s(_vm.author))])], 2)], 1)]) : _vm._e()];
      },
      proxy: true
    }, {
      key: "print",
      fn: function fn() {
        return undefined;
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }