import firebase from 'firebase/app';

const storage = firebase.storage();
const storageRef = storage.ref();

export function getFileUrl(fileName) {
  return storageRef.child(fileName).getDownloadURL();
}

export function fileDownload(url) {
  const excelLink = document.createElement('a');
  excelLink.setAttribute('href', url);
  excelLink.click();
  excelLink.remove();
}
