<template>
  <BaseSection
    :id="section.key"
    :is-visible="isSectionVisible"
    :is-modified="isDataChanged"
    :section="section"
    @onSectionSaveClick="save"
  >
    <template #section>
      <v-toolbar
        elevation="0"
      >
        <v-btn
          @click="createSubApplication"
          :disabled="FIELDS_DISABLED && !IS_CLIENT"
          text>
          <v-icon>mdi-account-plus</v-icon>
          NEW APPLICANT
        </v-btn>
        <v-btn
          @click.prevent="printApplication"
          text
        >
          <v-icon>mdi-printer</v-icon>
          PRINT
        </v-btn>
        <v-btn
          v-if="!IS_CLIENT"
          class="copy-btn"
          @click="copyDialogToggle"
          text
        >
          <v-icon>mdi-content-copy</v-icon>
          Copy
        </v-btn>

        <v-btn
          v-if="!IS_CLIENT"
          class="complain-btn"
          :class="{'with-content': isComplainContent}"
          @click.prevent="toggleComplain"
          text
        >
          <v-icon>mdi-bullhorn</v-icon>
          {{ complainTogglerText }} complain
        </v-btn>
      </v-toolbar>
        <v-col>
          <v-btn color='primary' @click='anzCalc' :loading='calcSaving'> ANZ calc </v-btn>
        </v-col>
      <v-dialog
        v-if="!IS_CLIENT"
        v-model="copyDialog"
        width="500"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Do You want to copy the application with all data?
          </v-card-title>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="copyDialogToggle"
              color="primary"
              text
            >
              No
            </v-btn>
            <v-btn
              @click="copyApplication"
              color="primary"
              text
            >
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <transition name="fade">
        <v-alert
          v-if="copyAlert"
          class="copy-alert"
          type="success"
        >
          Application was copied successfully!
        </v-alert>
      </transition>
      <v-card-text v-if="!IS_CLIENT" class="mt-4">
        <div class="mb-5">
          <v-container>
            <template>
              <v-row>
                <v-col>
                  <v-select
                    :disabled="FIELDS_EXEPT_DISABLED"
                    :items="statusOptions"
                    v-model="common.status"
                    label="Pipeline status"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    :items="viewOptions"
                    v-model="common.view"
                    label="Who can view the application"
                    :disabled="canNotChangeView || FIELDS_EXEPT_DISABLED"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    v-model="common.edit"
                    :items="editOptions"
                    label="Who can edit the application"
                    :disabled="canNotChangeEdit"
                    @change="editRoleChange"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    :disabled="FIELDS_EXEPT_DISABLED"
                    :value="common.client?.auth?.displayName"
                    label="Client"
                    readonly
                  >
                    <template #append>
                      <UserSelectDialog
                        @userSelected="clientChanged"
                      />
                    </template>
                    <template #append-outer>
                      <v-tooltip bottom>
                        <template #activator="{ on, attrs }">
                          <v-btn
                            :disabled="FIELDS_EXEPT_DISABLED"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon>mdi-email</v-icon>
                          </v-btn>
                        </template>
                        <span>Send invitation email</span>
                      </v-tooltip>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </template>
            <v-row v-if="isAdmin">
              <v-col>
                <v-autocomplete
                  return-object
                  autocomplete="nofill"
                  label="Adviser"
                  v-model="common.adviser"
                  :items="adviserList"
                  item-text="name"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <br>
            <p class="author"><b>Author:</b> {{ author}}</p>
          </v-container>
        </div>
      </v-card-text>
    </template>

    <template #print></template>
  </BaseSection>
</template>

<script>
import { mapGetters } from 'vuex';

import { clone } from '@/utils/object';

import SectionMixin from '@/mixins/SectionMixin';

import BaseSection from '@/components/ApplicationSections/BaseSection.vue';

import UserSelectDialog from '@/components/UserSelectDialog.vue';

import { hasAccess } from '../../services/section';

import
{
  copyApplicationData,
  getAdvisers,
  getAdminRole,
  setApplicationAdviser,
  setANZdata,
  getANZdata,
  setLinkStatus,
  getLinkStatus,
} from '@/services/firebase';

import { getFileUrl, fileDownload } from '@/services/file';

import { APPLICATION_STATUSES, USER_ROLES } from '@/models/helpers/consts';

export default {
  name: 'ApplicationSection',

  components: {
    BaseSection,
    UserSelectDialog,
  },

  mixins: [SectionMixin],

  props: {
    existedData: {
      type: Object,
      default() {
        return {};
      },
    },

    section: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      calcSaving: false,
      anzFlag: false,

      isSectionVisible: false,

      initialCommon: {},
      common: {},

      copyDialog: false,
      copyAlert: false,
      isAdmin: false,

      adviserList: [],

      editableField: [
        'status',
        'view',
        'edit',
      ],

      statusOptions: [
        {
          text: 'New',
          value: 'new',
        },
        {
          divider: true,
        },
        {
          text: 'Fact Find Completed',
          value: 'completed',
        },
        {
          text: 'Application Submitted',
          value: 'submitted',
        },
        {
          text: 'Conditional Approval',
          value: 'approval',
        },
        {
          text: 'Loan Structure Confirmed',
          value: 'confirmed',
        },
        {
          text: 'Loan Settled',
          value: 'settled',
        },
        {
          text: 'Commission Received',
          value: 'finalized',
        },
        {
          divider: true,
        },
        {
          text: 'Archived / Draft',
          value: 'archived',
        },
      ],

      viewOptions: [
        {
          text: 'Adviser only',
          value: 'adviser',
        },
        {
          text: 'Client and Adviser',
          value: 'both',
        },
      ],

      editOptions: [
        {
          text: 'Adviser only',
          value: 'adviser',
        },
        {
          text: 'Client only',
          value: 'client',
        },
      ],
    };
  },

  computed: {
    ...mapGetters(['COMPLAIN_STATUS', 'APPLICATION', 'SUB_APPLICATIONS', 'FIELDS_DISABLED', 'IS_CLIENT', 'ACTUAL_VERSION', 'FIELDS_EXEPT_DISABLED']),

    author() {
      return this.APPLICATION?.author;
    },

    parentId() {
      return this.$route.params.id;
    },

    isComplainContent() {
      const complain = this.APPLICATION?.data?.complain;

      const isComplain = Boolean(complain);

      return isComplain;
    },

    complainTogglerText() {
      if (this.COMPLAIN_STATUS) {
        return 'Hide';
      }

      return 'Show';
    },

    canNotChangeView() {
      const isApplicationNotNew = Boolean(this.common.status !== APPLICATION_STATUSES.new);

      return isApplicationNotNew;
    },

    canNotChangeEdit() {
      const isViewOnlyAdviser = this.common.view === USER_ROLES.adviser;

      const canNotChangeEdit = Boolean(this.canNotChangeView || isViewOnlyAdviser);

      return canNotChangeEdit;
    },
  },

  async created() {
    await this.checkIsSectionVisible();

    this.initialCommon = clone(this.existedData);
    this.common = clone(this.existedData);

    this.isAdmin = await getAdminRole();

    if (this.isAdmin) {
      this.adviserList = await getAdvisers();
    }
  },

  methods: {
    async anzCalc() {
      this.calcSaving = true;

      this.anzFlag = !this.anzFlag;

      const fileUrl = await getFileUrl('ANZ.xlsm');

      let majorCount = 0;
      let minorCount = 0;

      const now = new Date();

      if (this.SUB_APPLICATIONS.length) {
        this.SUB_APPLICATIONS.forEach((application) => {
          const birthday = application.data.personal_details.birth_date;

          const isBirhDate = Boolean(birthday);

          if (isBirhDate) {
            const birthDate = new Date(birthday);

            let age = now.getFullYear() - birthDate.getFullYear();
            const months = now.getMonth() - birthDate.getMonth();

            if (months < 0 || (months === 0 && now.getDate() < birthDate.getDate())) {
              age -= 1;
            }

            if (age > 18) {
              majorCount += 1;
            } else {
              minorCount += 1;
            }
          }
        });
      }

      let vehiclesCount = 0;

      const assets = this.APPLICATION.data.other_assets;

      if (assets.length) {
        assets.forEach((asset) => {
          const type = asset?.type;

          const isVehicle = Boolean(type === 'Vehicle');

          if (isVehicle) {
            vehiclesCount += 1;
          }
        });
      }

      const day = now.getDate();
      const dayFormatted = day < 10 ? `0${day}` : day;

      const month = now.getMonth() + 1;
      const monthFormatted = month < 10 ? `0${month}` : month;

      const fullDate = `${dayFormatted}/${monthFormatted}`;

      const data = {
        jointApplication: this.SUB_APPLICATIONS.length ? 'Y' : 'N',
        majorCount,
        minorCount,
        vehiclesCount,
        fullDate,
      };

      const payload = {
        anzFlag: this.anzFlag,
        url: fileUrl,
        data,
      };

      await setANZdata(payload);

      const interval = setInterval(async () => {
        const { linkStatus } = await getLinkStatus();

        if (linkStatus) {
          clearInterval(interval);
          const newFile = await getFileUrl('excel/ANZ/ANZ.xlsm');
          await fileDownload(newFile);
          const linkStatusPayload = {
            linkStatus: false,
          };
          await setLinkStatus(linkStatusPayload);

          this.calcSaving = false;
        }
      }, 1000);
    },

    async editRoleChange(event) {
      const userCanEdit = await hasAccess([event]);
      this.$store.commit('setFieldsDisabled', !userCanEdit);
    },

    goToSection(sectionId) {
      try {
        this.$vuetify.goTo(`#${sectionId}`, {
          duration: 300,
          offset: 0,
          easing: 'easeInOutCubic',
        });
      } catch (error) {
        console.log(error);
      }
    },

    async createSubApplication() {
      const newApplicant = await this.$store.dispatch('createApplication', this.parentId);
      const newApplicantId = await newApplicant.id;
      this.goToSection(`expansion-${newApplicantId}`);
    },

    copyDialogToggle() {
      this.copyDialog = !this.copyDialog;
    },

    async copyApplication() {
      this.copyDialog = false;
      this.copyAlert = true;

      const subApplicationsCopy = clone(this.SUB_APPLICATIONS);

      const newAppId = await copyApplicationData(this.$route.params.id, 'applications');

      for (let i = 0; i < subApplicationsCopy.length; i += 1) {
        await copyApplicationData(subApplicationsCopy[i].id, 'sub_applications', newAppId);
      }

      setTimeout(() => {
        this.copyAlert = false;
      }, 3000);
    },

    clientChanged(client) {
      this.common.client = client;
    },

    toggleComplain() {
      this.$store.commit('changeComplainStatus', !this.COMPLAIN_STATUS);
    },

    printApplication() {
      let path;

      if (this.IS_CLIENT) {
        path = 'ApplicationClientPrint';
      } else {
        path = 'ApplicationAdviserPrint';
      }

      this.$router.push(
        {
          name: path,
          params: {
            id: this.$route.params.id,
          },
        },
      );
    },
  },

  async mounted() {
    try {
      const { anzFlag } = await getANZdata();
      this.anzFlag = anzFlag;
    } catch (error) {
      const payload = {
        anzFlag: this.anzFlag,
        url: '',
      };
      await setANZdata(payload);
    }

    try {
      const { linkStatus } = await getLinkStatus();
      console.log(linkStatus);
      console.clear();
    } catch (error) {
      const payload = {
        linkStatus: false,
      };
      await setLinkStatus(payload);
    }
  },

  watch: {
    'APPLICATION.data.common.adviser.id': {
      handler(adviser) {
        setApplicationAdviser(this.APPLICATION.id, adviser);
      },
    },
  },
};
</script>

<style scoped>
.author {
  font-size: 16px;
}
.with-content {
  background: #FF5252 !important;
}
.copy-alert {
  position: fixed;
  left: 50%;
  top: 84px;
  transform: translateX(-50%);
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.buttons-wrapper {
  display: flex;
  align-items: center;
}
.complain-btn {
  margin-left: auto;
}
</style>
