var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-dialog', {
    attrs: {
      "scrollable": "",
      "max-width": "600px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "disabled": _vm.FIELDS_EXEPT_DISABLED,
            "icon": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-account-search-outline")])], 1)];
      }
    }]),
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v("Select User")]), _c('v-divider'), _c('v-card-text', {
    staticStyle: {
      "height": "500px"
    }
  }, [_c('v-list', [_c('v-list-item-group', {
    attrs: {
      "mandatory": "",
      "color": "indigo"
    },
    model: {
      value: _vm.selectedUser,
      callback: function callback($$v) {
        _vm.selectedUser = $$v;
      },
      expression: "selectedUser"
    }
  }, _vm._l(_vm.activeClients, function (user, index) {
    return _c('v-list-item', {
      key: user.uid
    }, [_c('v-list-item-content', [_c('v-list-item-title', {
      domProps: {
        "textContent": _vm._s(user.auth.displayName)
      }
    }), _c('v-list-item-subtitle', [_vm._v(" #" + _vm._s(index + 1) + " " + _vm._s(user.auth.email) + " " + _vm._s(user.auth.phone) + " ")])], 1)], 1);
  }), 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "color": "blue lighten-1",
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v(" Cancel ")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.userSelected
    }
  }, [_vm._v(" OK ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }